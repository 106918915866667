<template>
    <Popup
      class="payout-report"
      modal-id="payout-report-popup"
      :on-close="onModalClose"
      custom-class="gray-header"
      :toggle-status="true"
    >
      <template v-slot:header>
        {{ labels.payout_report }}
      </template>
  
      <template v-slot:body>
        <div v-if="settingsLoaded" class="payout-report__body">
            <FormulateInput type="number" v-model="budget" :label="labels.budget" class="payout-report__body__budget" />

            <div class="payout-report__body__spacer"></div>

            <div class="row">
                <div v-for="(contentType, index) in contentTypes" :key="`content-${index}`" class="col-lg-6 col-12 payout-report__content-type">
                    <FormulateInput type="number" v-model="contentTypes[index].weigth" :label="`${labels.weight_for} ${labels.content_type.toLowerCase()} - ${labels[contentType.labelKey]}`" />
                </div>
            </div>

            <div class="payout-report__body__spacer"></div>

            <div class="row payout-report__body__dates">
                <div class="col-lg-6 col-12">
                    <FormulateInput type="date" v-model="dateFrom" :label="labels.from" />
                </div>
                <div class="col-lg-6 col-12">
                    <FormulateInput type="date" v-model="dateTo" :label="labels.to" />
                </div>
            </div>
        </div>
      </template>
  
      <template v-slot:footer>
        <div class="payout-report__footer">
        <Button
            :label="labels.generate"
            variant="btn-purple"
            font-weight="600"
            :action="requestPayout"
        />
        <Button
            :label="labels.close"
            variant="btn-outline-purple"
            font-weight="600"
            :action="onModalClose"
          />
        </div>
      </template>
    </Popup>
  </template>
  
  <script>
  import { mapActions, mapState, mapGetters } from "vuex";
  import Popup from "@/components/Popups/Popup";
  import Button from "@/components/Buttons/Button";
  
  export default {
    components: {
      Popup,
      Button,
    },
    data() {
      return {
        settingsLoaded: false,
        budget: 0,
        dateFrom: Date.now(),
        dateTo: Date.now(),
        contentTypes: [
            {
                model: 'Module',
                labelKey: 'modules',
                weigth: 0.77
            },
            {
                model: 'Card',
                labelKey: 'cards',
                weigth: 0.1,
                extraConditions: {
                    kind: 0
                }
            },
            {
                model: 'Card',
                labelKey: 'definitions',
                weigth: 0.1,
                extraConditions: {
                    kind: 1
                }
            }
        ]
      };
    },
    computed: {
      ...mapState("helper", ["countries","admin_roles"]),
      ...mapGetters("helper", ["hasPermissions", "labels"]),
      isAllowed() {
        return this.hasPermissions(["super", "system"]);
      }
    },
    methods: {
      ...mapActions("notification", ["addNotification"]),
      ...mapActions("user", ["generatePayout"]),
      ...mapActions("helper", ["getPayoutSettings"]),
      onModalClose() {
        if (this.routeBack && Object.keys(this.routeBack).length) {
          this.$router.push(this.routeBack);
        } else {
          this.$router.push({
            name: "users",
            params: { kind: this.$route.params.kind },
            query: this.$route.query
          });
        }
      },
      requestPayout() {
        let payload = {
            from: this.dateFrom,
            to: this.dateTo,
            contentTypes: this.contentTypes,
            budget: this.budget,
            author: 'all'
        };
        this.generatePayout(payload)
        //.then((res) => {}).catch((err) => console.log(err));
      },
      loadSettings() {
        this.getPayoutSettings().then((res) => {
          if (res.success) {
            for (const [key, value] of Object.entries(res.data)) {
              this[key] = value;
            }
          }

          this.settingsLoaded = true;
        }).catch((err) => console.log(err));
      }
    },
    mounted() {
      if (!this.isAllowed && this.admin_roles) {
        this.$router.push({ name: "dashboard" });
      }
      this.loadSettings();
    },
    beforeDestroy() {
      let element = document.querySelector(".modal-backdrop");
      if (element) {
        element.remove();
        document.querySelector("body").classList.remove("modal-open");
      }
    },
    watch: {
      admin_roles() {
        if (!this.isAllowed) {
          this.$router.push({ name: "dashboard" });
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .payout-report {
    &__header-title {
      font-family: "Oswald", sans-serif;
    }
  
    &__body {
        &__budget {
            margin-bottom: 0px;
        }

        &__dates {
            div[class*='col-'] {
                margin-top: 15px;
            }
        }

        &__spacer {
            width: 100;
            height: 1px;
            background-color: rgba(0,0,0,0.3);
            margin-top: 15px;
        }
      ::v-deep .formulate-input {
        .formulate-input-wrapper {
          label {
            font-family: "Oswald", sans-serif;
            margin-bottom: 10px;
          }
  
          .formulate-input-element {
            max-width: 100%;
          }
  
          .formulate-input-group {
            display: flex;
            .formulate-input-group-item {
              margin-right: 10px;
              margin-bottom: 0;
            }
            label {
              margin-bottom: 0;
              font-family: inherit;
              font-weight: inherit;
            }
          }
        }
      }
    }

    &__content-type {
        margin-top: 15px;

        ::v-deep {
            .formulate-input-wrapper {
                display: flex;
                align-items: center;

                label {
                    margin-bottom: 0px;
                    margin-right: 15px;
                    flex: 1 0 auto;
                }

                .formulate-input-element {
                    width: 80px;
                    text-align: right;
                }
            }
        }
    }
  
    &__footer {
      display: flex;
      align-items: center;
      margin-left: -15px;
      margin-right: -15px;

      button {
        margin: 0 15px;
      }
  
      ::v-deep .formulate-input {
        margin-bottom: 0px !important;
  
        .formulate-input-element {
          button {
            margin-bottom: 0px;
            margin-right: 15px;
          }
        }
      }
    }
  }
  </style>
  